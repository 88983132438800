import React from "react";

const Input = ({
  label,
  style,
  error,
  onChange,
  value,
  type = "text",
  className,
  multiline = false,
  containerStyle,
  noMb,
  inputProps,
  ...otherProps
}) => {
  return (
    <div className={`form-group ${noMb ? "" : "mb-3"}`} style={containerStyle}>
      {label && <label>{label}</label>}
      {multiline ? (
        <textarea
          {...otherProps}
          {...inputProps}
          className={`form-control ${error ? "is-invalid" : ""} ${className}`}
          style={style}
          {...(onChange || inputProps?.onChange
            ? { value: value, onChange: onChange }
            : { defaultValue: "" })}
          value={value}
          cols="40"
          rows="8"
        />
      ) : (
        <input
          {...otherProps}
          {...inputProps}
          className={`form-control ${type === "file" ? "form-control-file" : ""} ${error ? "is-invalid" : ""} ${className}`}
          style={style}
          {...(onChange || inputProps?.onChange
            ? { value: value, onChange: onChange }
            : { defaultValue: "" })}
          value={value}
          type={type}
        />
      )}
      <span className="help-block text-danger">{error}</span>
    </div>
  );
};

export default Input;
