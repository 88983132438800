import * as zod from "zod";

export const schemaForm = zod.object({
  header: zod.string().min(1, "Kategori wajib diisi"),
  produk: zod.array(
    zod.object({
      nama: zod.string().min(1, "nama produk wajib diisi"),
      urlimg: zod.string().min(1, "icon wajib diisi"),
      harga: zod.number().min(1, "harga tidak boleh kosong"),
      link: zod.string().min(1, "link wajib diisi"),
    })
  ),
});
